import Link from 'next/link'

export const Navbar = () => {
  return (
    <div className="navbar fr">
      <Link href="/" legacyBehavior>
        <a className="title tab">MoneyCoach</a>
      </Link>
      <Link href="/videos" legacyBehavior>
        <a className="tab">Videos</a>
      </Link>
      <Link href="/recommendations" legacyBehavior>
        <a className="tab">Products</a>
      </Link>
      <Link href="/calculators" legacyBehavior>
        <a className="final tab">Calculators</a>
      </Link>
    </div>
  )
}
